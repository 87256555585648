<template>
  <div class="point-mirror">
    <mirror-header
      :categories="getCategories"
      :business-units="getBusinessUnits"
      :branches="getBranches"
      @branchesByBusinessUnit="fetchBranches"
      @fetchMirrorsByFilters="fetchMirrorsByFilters"
    />

    <mirror-list
      :users="mirrorsData"
      @showUserDetails="showUserDetails"
    />
    <v-row
      justify="end"
    >
      <pagination
        v-if="showPagination"
        :pagination-settings="mirrorsPagination"
        :page="getPage"
        @changed="changePage"
      />
    </v-row>
  </div>
</template>

<script>
import formats from '@/mixins/formats';
import { mapGetters, mapActions } from 'vuex';
import MirrorHeader from '@/components/headers/MirrorHeader.vue';
import MirrorList from '@/components/lists/MirrorList.vue';
import Pagination from '@/components/pagination/Pagination.vue';

export default {
  name: 'PointMirror',
  components: {
    MirrorHeader,
    MirrorList,
    Pagination,
  },
  mixins: [formats],
  data: () => ({
    dateInit: '',
    dateEnd: '',
    categoryId: null,
    params: {},
    context: 'mirrorList',
  }),
  computed: {
    mirrorsData() {
      if (this.getMirrors.data) {
        return this.getMirrors.data;
      }
      return [];
    },
    mirrorsPagination() {
      if (this.getMirrors.pagination_settings) {
        return this.getMirrors.pagination_settings;
      }
      return {};
    },
    showPagination() {
      if (this.getMirrors.data && this.getMirrors.data.length !== 0) {
        return true;
      }
      return false;
    },
    ensureDriverProfile() {
      if (this.getDriverProfile === 0) {
        return '';
      }
      return this.getDriverProfile;
    },
    ...mapGetters([
      'getMirrors',
      'getCategories',
      'getDateRange',
      'getDriverProfile',
      'getComponentFatherDate',
      'getPage',
      'getSearchTerm',
      'getBusinessUnits',
      'getBranches',
    ]),
  },
  mounted() {
    if (!this.getComponentFatherDate || this.getComponentFatherDate !== this.context) {
      this.saveDateFatherComponent(this.context);
      this.saveDateRange(this.currentMonth());
    }
    this.initLoading();
    this.fetchCategories();
    this.fetchBusinessUnits({ by_active: true });
    this.fetchBranches({ by_active: true });
    this.fetchMirrors({
      date_init: this.getDateRange[0],
      date_end: this.getDateRange[1],
      page: this.getPage,
      by_term: this.getSearchTerm,
      by_driver_profile: this.ensureDriverProfile,
    });
    this.initCurrentBreadcrumbs([
      {
        text: this.$i18n.t('breadcrumbs.point_mirror'),
        disabled: true,
      },
    ]);
  },
  methods: {
    ...mapActions([
      'fetchMirrors',
      'fetchCategories',
      'initCurrentBreadcrumbs',
      'saveDateFatherComponent',
      'saveDriverProfile',
      'saveSearchTerm',
      'saveDateRange',
      'updatePage',
      'fetchBusinessUnits',
      'fetchBranches',
      'initLoading',
    ]),

    fetchMirrorsByFilters(params) {
      const payload = params;

      this.updatePage(1);
      this.saveSearchTerm(payload.by_term);

      this.dateInit = payload.date_init;
      this.dateEnd = payload.date_end;
      this.categoryId = payload.by_driver_profile;

      this.fetchMirrors(payload);
    },

    showUserDetails(userId) {
      const routeData = this.$router.resolve({
        name: 'mirrorListShow',
        params: {
          id: userId,
        },
        query: {
          date_init: this.getDateRange[0],
          date_end: this.getDateRange[1],
        },
      });

      window.open(routeData.href, '_blank');
    },

    changePage() {
      this.params = {
        page: this.getPage,
        date_init: this.getDateRange[0],
        date_end: this.getDateRange[1],
        by_driver_profile: this.categoryId,
      };
      this.fetchMirrors(this.params);
    },
  },
};
</script>
