<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        outlined
        label="Período"
        height="42"
        dense
        header-color="accent"
        color="accent"
        readonly
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          slot="prepend-inner"
          left
          color="accent"
        >
          mdi-calendar
        </v-icon>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="dates"
      no-title
      range
      scrollable
      :locale="`${this.$i18n.locale}`"
    >
      <v-spacer />
      <v-btn
        text
        color="primary"
        @click="cancel"
      >
        {{ $t('date_picker.cancel') }}
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="saveDates"
      >
        {{ $t('date_picker.ok') }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import formats from '@/mixins/formats';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DateRange',
  mixins: [formats],
  props: {
    customDate: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    dates: [],
    menu: false,
    menuClosedSuccess: false,
  }),
  computed: {
    ...mapGetters([
      'getDateRange',
      'getComponentFatherDate',
    ]),
    dateRangeText() {
      this.orderDates();
      return `${this.formatDateISO(this.dates[0])} - ${this.formatDateISO(this.dates[1])}`;
    },
  },
  watch: {
    menu(value) {
      if (!value) {
        if (this.dates.length === 2 && this.menuClosedSuccess) {
          return;
        }
        this.dates = this.getDateRange;
      } else {
        this.menuClosedSuccess = false;
      }
    },
    customDate(value) {
      if (value) {
        this.dates = value;
        this.saveDateRange(value);
        this.saveDateFatherComponent(this.$route.meta.context);
        this.$emit('datesSelected', this.dates);
      }
    },
  },
  created() {
    if (this.getDateRange.length === 0) {
      this.initDates();
    } else {
      this.dates = this.getDateRange;
    }
  },
  methods: {
    cancel() {
      this.menu = false;
      this.dates = this.getDateRange;
    },
    saveDates() {
      if (this.dates && this.dates.length === 2) {
        this.menuClosedSuccess = true;
        this.saveDateRange(this.dates);
        this.$refs.menu.save(this.dates);
        this.menu = false;
        this.$emit('datesSelected', this.dates);
      } else {
        this.addErrorMessage(this.$t('date_picker.message.incomplete'));
      }
    },
    ...mapActions([
      'saveDateRange',
      'addErrorMessage',
      'saveDateFatherComponent',
    ]),
    initDates() {
      const dateToday = new Date();
      const dateLastMonth = new Date();
      dateLastMonth.setMonth(dateLastMonth.getMonth() - 1);

      this.saveDateFatherComponent(this.$route.meta.context);
      this.saveDateRange([this.formatDate(dateLastMonth, 'yyyy-MM-dd'), this.formatDate(dateToday, 'yyyy-MM-dd')]);

      this.dates = this.getDateRange;
    },
    orderDates() {
      this.dates.sort((a, b) => new Date(a) - new Date(b));
    },
  },
};
</script>
