.<template>
  <div
    class="text-center placeholder"
  >
    <v-icon
      color="list_placeholder"
      class="placeholder-icon"
    >
      mdi-truck-outline
    </v-icon>

    <p class="text">
      {{ message }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'Placeholder',
  props: {
    message: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.placeholder {
  margin-top: 120px;

  .placeholder-icon {
    font-size: 100px;
  }

  .text {
    margin: auto;
    font-size: 32px;
    width: 330px;
    color: $color_placeholder_text;
  }
}
</style>
