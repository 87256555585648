<template>
  <div>
    <v-simple-table
      v-if="usersPresent"
      fixed-header
      height="600px"
      class="jornada-list"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t('components.lists.drivers.name') }}
            </th>
            <th class="text-center">
              {{ $t('components.lists.drivers.cpf') }}
            </th>
            <th class="text-center">
              {{ $t('components.lists.mirrors.drive') }}
            </th>
            <th class="text-center">
              {{ $t('components.lists.mirrors.available') }}
            </th>
            <th class="text-center">
              {{ $t('components.lists.mirrors.wait') }}
            </th>
            <th class="text-center">
              {{ $t('components.lists.mirrors.meal') }}
            </th>
            <th class="text-center">
              {{ $t('components.lists.mirrors.rest') }}
            </th>
            <th class="text-center">
              {{ $t('components.lists.mirrors.overnight') }}
            </th>
            <!-- <th class="text-center">
              {{ $t('components.lists.mirrors.pay_drive') }}
            </th> -->
            <th class="text-center">
              {{ $t('components.lists.mirrors.pay_wait') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="user in users"
            :key="user.id"
            class="list-row"
            @click="showUser(user.id)"
          >
            <td>
              {{ user.name }}
            </td>
            <td class="text-center">
              {{ user.cpf }}
            </td>
            <td class="text-center mirror">
              {{ formatHour(user.mirror.drive_time) }}
            </td>
            <td class="text-center mirror">
              {{ formatHour(user.mirror.available_time) }}
            </td>
            <td class="text-center mirror">
              {{ formatHour(user.mirror.wait_time) }}
            </td>
            <td class="text-center mirror">
              {{ formatHour(user.mirror.meal_time) }}
            </td>
            <td class="text-center mirror">
              {{ formatHour(user.mirror.rest_time) }}
            </td>
            <td class="text-center mirror">
              {{ formatHour(user.mirror.overnight_time) }}
            </td>
            <!-- <td class="text-center mirror">
              {{ formatHour(user.mirror.pay_drive_time) }}
            </td> -->
            <td class="text-center mirror">
              {{ formatHour(user.mirror.pay_wait_time) }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <placeholder
      v-else
      :message="$t('components.lists.users.empty')"
    />
  </div>
</template>

<script>
import Placeholder from '@/components/lists/Placeholder.vue';
import formats from '@/mixins/formats';

export default {
  name: 'MirrorList',
  components: {
    Placeholder,
  },
  mixins: [formats],
  props: ['users'],
  computed: {
    usersPresent() {
      return this.users.length !== 0;
    },
  },
  methods: {
    showUser(userId) {
      this.$emit('showUserDetails', userId);
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/lists/drivers.scss" scoped />
