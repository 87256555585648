<template>
  <v-pagination
    v-model="pagination"
    class="pagination"
    :value="paginationPage"
    color="transparent"
    :length="paginationSize"
    :total-visible="7"
    @input="emitChange"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Pagination',
  props: {
    paginationSettings: {
      type: Object,
      default: () => {},
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  computed: {
    ...mapGetters([
      'getPage',
    ]),
    paginationSize() {
      return this.paginationSettings ? this.paginationSettings.pages : 1;
    },
    paginationPage() {
      return this.paginationSettings ? this.paginationSettings.page : 1;
    },
  },
  watch: {
    page(value) {
      this.pagination = value;
    },
  },
  mounted() {
    this.pagination = this.getPage;
  },
  methods: {
    ...mapActions([
      'initLoading',
      'updatePage',
    ]),
    emitChange(page) {
      if (this.getPage !== page) {
        this.initLoading();
        this.updatePage(page);
        this.$emit('changed');
        this.scrollToTop();
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/pagination/pagination.scss"/>
