<template>
  <v-container
    class="mirror-header"
  >
    <v-row
      class="content pa-0"
    >
      <v-col
        class="px-2 d-flex"
        cols="12"
        sm="12"
      >
        <v-text-field
          v-model="term"
          v-debounce="applyFilters"
          outlined
          dense
          :label="`${ text('user') }`"
          loader-height="1"
          height="42"
          class="search"
        >
          <v-icon
            slot="prepend-inner"
            left
            olor="accent"
          >
            mdi-magnify
          </v-icon>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row
      class="content pa-0"
    >
      <v-col
        class="px-2 d-flex"
        cols="12"
        sm="3"
      >
        <date-range @datesSelected="saveDatesAndApplyFilters" />
      </v-col>
      <v-col
        cols="12"
        sm="3"
        class="px-2"
      >
        <v-select
          v-model="businessUnitId"
          :items="formatedUnits"
          :label="$t('components.header.mirrors.unit')"
          item-text="name"
          item-value="id"
          color="accent"
          dense
          height="42"
          outlined
        >
          <v-icon
            slot="prepend-inner"
            left
            color="accent"
          >
            mdi-domain
          </v-icon>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        sm="3"
        class="px-2"
      >
        <v-select
          v-model="branchId"
          :items="formattedBranches"
          :label="$t('components.header.mirrors.branch')"
          :disabled="hasBusinessUnitId"
          item-text="name"
          item-value="id"
          color="accent"
          dense
          height="42"
          outlined
          @input="applyFiltersLoading"
        >
          <v-icon
            slot="prepend-inner"
            left
            color="accent"
          >
            mdi-storefront-outline
          </v-icon>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        sm="3"
        class="px-2"
      >
        <v-select
          v-model="categoryId"
          :items="formattedCategories"
          :label="$t('components.header.mirrors.category')"
          item-text="name"
          item-value="id"
          color="accent"
          dense
          height="42"
          outlined
          @input="applyFiltersLoading"
        >
          <v-icon
            slot="prepend-inner"
            left
            color="accent"
          >
            mdi-truck-outline
          </v-icon>
        </v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import formats from '@/mixins/formats';
import { mapGetters } from 'vuex';
import DateRange from '@/components/forms/calendar/DateRange.vue';

export default {
  name: 'MirrorHeader',
  components: {
    DateRange,
  },
  mixins: [formats],
  props: {
    businessUnits: {
      type: Array,
      default: () => [],
    },
    branches: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    term: '',
    dates: [],
    formattedCategories: [],
    businessUnitId: '',
    branchId: null,
    categoryId: null,
    formattedBranches: [],
    formatedUnits: [],
  }),
  computed: {
    ...mapGetters([
      'getDateRange',
      'getSearchTerm',
    ]),

    hasBusinessUnitId() {
      return !this.businessUnitId;
    },
  },
  watch: {
    businessUnitId(value) {
      this.branchId = null;
      if (value) {
        this.$emit('branchesByBusinessUnit', { business_unit_id: value, by_active: true });
      } else {
        this.applyFiltersLoading();
      }
    },
    branches(value) {
      this.applyFormattedBranches(value);
    },
    teste() {

    },
    categories(value) {
      this.applyFormattedCategories(value);
    },
    businessUnits(value) {
      this.applyFormattedUnits(value);
    },
  },
  mounted() {
    this.term = this.getSearchTerm;
    this.driverProfile = this.getDriverProfile;
    this.applyFormattedBranches(this.branches);
    this.applyFormattedCategories(this.categories);
    this.applyFormattedUnits(this.businessUnits);
  },
  methods: {
    applyFormattedUnits(units) {
      this.formatedUnits = [];
      this.formatedUnits = [{ name: `${this.text('all')}`, id: null }];
      this.formatedUnits.push(...units);
    },
    applyFormattedBranches(branches) {
      this.formattedBranches = [];
      this.formattedBranches = [{ name: `${this.text('all')}`, id: '' }];
      this.formattedBranches.push(...branches);
    },
    applyFormattedCategories(categories) {
      this.formattedCategories = [{ name: this.text('all'), id: null }, ...categories];
    },

    text(label) {
      return this.$t(`components.header.mirrors.${label}`);
    },
    saveDatesAndApplyFilters(dates) {
      this.dates = dates;
      this.applyFiltersLoading();
    },
    applyFiltersLoading() {
      this.$store.dispatch('initLoading');
      this.applyFilters();
    },
    applyFilters() {
      this.$emit('fetchMirrorsByFilters', this.formatPayload());
    },
    formatPayload() {
      return {
        date_init: this.getDateRange[0],
        date_end: this.getDateRange[1],
        by_term: this.term,
        unit_id: this.businessUnitId,
        branch_id: this.branchId,
        by_driver_profile: this.categoryId,
      };
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/headers/mirrors.scss" scoped />
